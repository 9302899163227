import React, { createContext, useContext, useEffect } from 'react'
import { BehaviorSubject, Subject, fromEvent } from 'rxjs'
import { auditTime, map, distinctUntilChanged } from 'rxjs/operators'
import { contentWidth } from '~/config'
import { isBrowser } from '~/utils'

export enum AppEvents {
  ON_CLIENT_ENTRY          = 'ON_CLIENT_ENTRY',
  ON_INITIAL_CLIENT_RENDER = 'ON_INITIAL_CLIENT_RENDER',
  ON_RENDER_COMPLETE       = 'ON_RENDER_COMPLETE',
  ON_FIRST_RENDER_COMPLETE = 'ON_FIRST_RENDER_COMPLETE'
}

export enum AppActions {
  TOGGLE_DRAWER = 'TOGGLE_DRAWER',
  CLOSE_DRAWER  = 'CLOSE_DRAWER'
}

const { TOGGLE_DRAWER, CLOSE_DRAWER } = AppActions

const initialWidth = isBrowser() ? window.innerWidth : contentWidth

export class App {
  static Events = AppEvents
  static Actions = AppActions

  public eventBus$ = new Subject<AppEvents>()
  public subscribe = this.eventBus$.subscribe

  public pageWidth$ = new BehaviorSubject<number>(initialWidth)
  get pageWidth() {
    return this.pageWidth$.getValue()
  }

  protected _drawer$ = new BehaviorSubject<boolean>(false)
  public drawer$ = this._drawer$.pipe()
  public get drawer() {
    return this._drawer$.getValue()
  }

  constructor() {
    if (isBrowser()) this._initForBrowser()
  }

  protected _initForBrowser = () => {
    fromEvent(window, 'resize')
      .pipe(
        auditTime(200),
        map((ev) => (ev.target as Window).innerWidth),
        distinctUntilChanged()
      )
      .subscribe(this.pageWidth$)
  }

  dispatch(actionType: AppActions, data?: any) {
    switch (actionType) {
      case TOGGLE_DRAWER:
        this._drawer$.next(!this.drawer)
        break
      case CLOSE_DRAWER:
        this._drawer$.next(false)
        break
    }
  }
}

export const app = new App()
export const appContext = createContext<App>(app)
export const useAppContext = () => useContext<App>(appContext)

const { Provider } = appContext

const AppProvider: React.FC = ({ children }) => {
  useEffect(() => {
    app.eventBus$.next(AppEvents.ON_FIRST_RENDER_COMPLETE)
  }, [])
  useEffect(() => {
    app.eventBus$.next(AppEvents.ON_RENDER_COMPLETE)
  })
  return <Provider value={app}>{children}</Provider>
}

export default AppProvider
