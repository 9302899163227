module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":720,"quality":85}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Храм святителя Николая в Валерьяново","short_name":"NicolaHramVlr.by","start_url":"/","background_color":"#E2AA2B","theme_color":"#fff","display":"minimal-ui","icon":"/Users/andrey/Developer/1-active/NikolaHram/site/gatsby/../src/assets/fav.png","include_favicon":true,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"fc3e0ac0c532c38198d966f5f59765b2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"70939810","webvisor":false,"trackHash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"gatsby","projectRoot":"/Users/andrey/Developer/1-active/NikolaHram/site"},
    }]
