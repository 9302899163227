import React, { useEffect } from 'react'
import styled from 'styled-components'
import AppProvider, {useAppContext} from './providers/AppProvider'
import ApiProvider from './providers/ApiProvider'

const AppRoot: React.FC<{ element: React.Component }> = ({ element }) => {
  return (
    <AppProvider>
      <ApiProvider>{element}</ApiProvider>
    </AppProvider>
  )
}

export default AppRoot
