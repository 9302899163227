export const title = `Храм свт. Николая в Валерьяново`
export const description = `Храм святителя Николая в деревне Валерьяново. История прихода. Расписание Богослужений. Помочь строительству. Контакты.`
export const author = `Приход в честь свт. Николая в д. Валерьяново`
export const lang = 'ru'
export const siteUrl = `https://nikolahramvlr.by`
export const keywords = [
  'храм валерьяново',
  'храм святителя Николая',
  'храм святителя Николая валерьяново',
  'храм святителя Николая расписание богослужений',
  'храм святителя Николая расписание',
  'храм святителя Николая схема проезда',
  'храм святителя Николая адрес',
  'храм святителя Николая контакты',
  'храм святителя Николая помочь строительству',
  'храм святителя Николая телефоны',
  'храм святителя Николая пожертвовать',
  'храм святителя Николая о. Илья',
  'храм валерьяново расписание богослужений',
  'храм валерьяново расписание',
  'храм валерьяново схема проезда',
  'храм валерьяново адрес',
  'храм валерьяново контакты',
  'храм валерьяново помочь строительству',
  'храм валерьяново телефоны',
  'храм валерьяново пожертвовать',
  'храм валерьяново о. Илья',
  'храм свт Николая',
  'храм свт Николая Чудотворца',
  'храм свт Николая Угодника',
  'храм свт Николая в Минске',
  'храм в деревне валерьяново',
  'храм копище',
  'храм новая боровая',
  'храм боровая',
  'храм валерьяново',
  'приход святителя Николая',
  'приход святителя Николая валерьяново',
  'приход святителя Николая расписание богослужений',
  'приход святителя Николая расписание',
  'приход святителя Николая схема проезда',
  'приход святителя Николая адрес',
  'приход святителя Николая контакты',
  'приход святителя Николая помочь строительству',
  'приход святителя Николая телефоны',
  'приход святителя Николая пожертвовать'
]

export default {
  title,
  description,
  author,
  lang,
  siteUrl,
  keywords: keywords.join(', ')
}