export default {
  primaryColor: '#FAF6EE',
  secondaryColor: '#382600',
  contentBackground1: '#f6efe1',
  contentBackground2: '#f6efe1',
  linkColor: '#984444',
  bodyColor: 'hsl(0, 0%, 20%)',
  headerColor: 'hsl(0, 0%, 20%)',
  lightRed1: '#f9b0af',
  lightBlue1: '#9fc2ee',
  darkRed1: '#a40000',
  darkBlue1: '#3d81d6'
}