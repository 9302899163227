import { default as settings } from './settings'
import { default as colors } from './colors'
import { default as metadata } from './metadata'
import { default as typography } from './typography'
import { default as breakpoints } from './breakpoints'

export * from './metadata'
export * from './settings'

export default {
  settings,
  metadata,
  colors,
  typography,
  breakpoints
}
