import AppRoot from '../src/AppRoot'
import { app, App } from '../src/providers'
import createGoogleFontLink from '../src/utils/googleFontLink'
import { googleFontsConfig  } from '../src/config/typography'

export const onInitialClientRender = () => {
  const { fonts, subsets, display } = googleFontsConfig
  const preconnectLink = document.createElement('link')
  preconnectLink.rel = 'preconnect'
  preconnectLink.href = 'https://fonts.gstatic.com'
  const stylesheetLink = document.createElement('link')
  stylesheetLink.rel = 'stylesheet'
  stylesheetLink.href = createGoogleFontLink(fonts, subsets, display)
  document.head.appendChild(preconnectLink)
  document.head.appendChild(stylesheetLink)
  app.eventBus$.next(App.Events.ON_CLIENT_ENTRY)
}

export const onClientEntry = () => {
  app.eventBus$.next(App.Events.ON_INITIAL_CLIENT_RENDER)
}

export const onPreRouteUpdate = (...args) => {
  // console.log('onPreRouteUpdate', args)
}

export const onPrefetchPathname = (...args) => {
  // console.log('onPrefetchPathname', args)
}

export const onPostPrefetchPathname = (...args) => {
  // console.log('onPostPrefetchPathname', args)
}

export const wrapRootElement = AppRoot
