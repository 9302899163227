export const breakpoints = [
  {
    sidebarWidth: '80vw',
    contentWidth: '100vw'
  },
  {
    minWidth: `600px`,
    sidebarWidth: '44vw',
    contentWidth: '56vw'
  },
  {
    minWidth: `720px`,
    sidebarWidth: '320px',
    contentWidth: '(100vw - 320px)'
  },
  {
    minWidth: `1090px`,
    sidebarWidth: '320px',
    contentWidth: '720px'
  }
]

export default breakpoints
