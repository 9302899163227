import React, { createContext, useContext } from 'react'
import { BehaviorSubject, Subject, Observable, fromEvent, of, merge } from 'rxjs'
// import { fromFetch } from 'rxjs/fetch'
// import {
//   timeout,
//   auditTime,
//   map,
//   tap,
//   distinctUntilChanged,
//   filter,
//   switchMap,
//   catchError,
//   delay,
//   retryWhen,
//   mapTo
// } from 'rxjs/operators'
// import { getBrowserFingerprint, murmurHash } from '~/utils'
// import { app, App } from '~/providers'

// const API_BASE = 'https://nikolahramvlr.by/api'

enum ApiEvents {}
enum ApiActions {}

// const FETCH_TIMEOUT = 5000
// const RETRY_TIMEOUT = 5000

// const {  } = ApiActionTypes

class Token {
  constructor(protected token: string) {}

  get header() {
    return this.token && JSON.parse(atob(this.token.split('.')[0]))
  }

  get payload() {
    return this.token && JSON.parse(atob(this.token.split('.')[1]))
  }

  get expiresIn() {
    return this.token && this.payload.exp
  }

  get issuedAt() {
    return this.token && this.payload.iat
  }

  get isValid() {
    if (!this.token) return false
    if (this.token === 'string') return false
    if (this.payload.exp * 1000 < Date.now()) return false
    return true
  }

  valueOf() {
    return this.token
  }
}

abstract class Adapter {}
class AjaxAdapter extends Adapter {}
class WebSocketAdapter extends Adapter {}

// function generateClientId() {
//   const fp = getBrowserFingerprint()
//   const p1 = murmurHash(JSON.stringify(fp)).toString(32)
//   const p2 = Math.floor(Math.random() * 0xffffffff).toString(32)
//   const p3 = Date.now().toString(32)
//   return `${p1}.${p2}.${p3}`
// }

function getRegenTimeout(token: Token) {
  // return ((token.expiresIn - this.issuedAt) >> 1) * 1000
  return (token.expiresIn * 1000 - Date.now()) >> 1
}

function getLogoutTimeout(token: Token) {
  return token.expiresIn * 1000 - Date.now()
}

export class ApiPort {
  static Events = ApiEvents
  static Actions = ApiActions

  public browserId: string
  public uniqueId: string
  public timestampId: string
  public get clientId() {
    return `${this.browserId}.${this.uniqueId}.${this.timestampId}`
  }
  public clientId$ = new Subject<string>()
  // protected _token: Token
  // public get token() {
  //   if (!this._token) return null
  //   if (!this._token.isValid) return null
  //   return this._token
  // }
  // public token$ = new Subject<Token>()

  public apiAvailable$ = new BehaviorSubject<boolean>(true)

  // protected login$ = this.clientId$.pipe(
  //   tap(v => console.log('login starts for', v)),
  //   switchMap(clientId => {
  //     return fromFetch(`${API_BASE}/`, {
  //       // credentials: 'include',
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json;charset=utf-8' },
  //       body: JSON.stringify({ clientId }),
  //       selector: response => response.text()
  //     }).pipe(
  //       timeout(FETCH_TIMEOUT),
  //       catchError(error => {
  //         console.log('catchError', error)
  //         throw error
  //       })
  //     )
  //   }),
  //   retryWhen(e$ =>
  //     e$.pipe(
  //       tap(e => console.warn('connection to api server failed', e.message)),
  //       tap(v => this.apiAvailable$.next(false)),
  //       delay(RETRY_TIMEOUT),
  //       tap(v => console.log('retry', v))
  //     )
  //   ),
  //   tap(v => console.log('token received', v)),
  //   map(v => new Token(v)),
  //   tap(v => v && this.apiAvailable$.next(true))
  // )

  // protected regen$ = this.token$.pipe(
  //   filter(t => t && t.isValid),
  //   tap(v => console.log('regen starts for', v.valueOf())),
  //   switchMap(oldToken => {
  //     const timeout = getRegenTimeout(oldToken)
  //     console.log('delay', timeout)
  //     return of(oldToken).pipe(delay(timeout))
  //   }),
  //   tap(v => console.log('continue regen for', v.valueOf())),
  //   switchMap(oldToken => {
  //     return fromFetch(`${API_BASE}/`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json;charset=utf-8',
  //         'X-Access-Token': oldToken.valueOf()
  //       },
  //       body: JSON.stringify({}),
  //       selector: response => response.text()
  //     }).pipe(timeout(FETCH_TIMEOUT))
  //   }),
  //   retryWhen(e$ =>
  //     e$.pipe(
  //       tap(e => console.warn('connection to api server failed', e.message)),
  //       tap(v => this.apiAvailable$.next(false)),
  //       delay(RETRY_TIMEOUT)
  //     )
  //   ),
  //   tap(v => console.log('regen token received', v)),
  //   map(v => new Token(v)),
  //   tap(v => v && this.apiAvailable$.next(true))
  // )

  // protected logout$ = this.token$.pipe(
  //   switchMap(token => {
  //     return of(token).pipe(delay(getLogoutTimeout(token)))
  //   }),
  //   tap(v => v && this.apiAvailable$.next(false)),
  //   map(v => v.payload.clientId),
  // )

  constructor() {
    // console.log('constructor Api')
    // app.eventBus$.pipe(filter(e => e === App.Events.ON_INITIAL_CLIENT_RENDER)).subscribe(this._init)
  }

  // protected _init = async () => {
  //   this.browserId = murmurHash(JSON.stringify(getBrowserFingerprint())).toString(32)
  //   this.uniqueId = Math.floor(Math.random() * 0xffffffff).toString(32)
  //   this.timestampId = Date.now().toString(32)
  //   console.log('initialize API', this.clientId)
  //   merge(this.login$, this.regen$).subscribe(this.token$)
  //   this.token$.subscribe(v => (this._token = v))
  //   this.logout$.subscribe(this.clientId$)
  //   this.clientId$.next(this.clientId)
  // }

  // dispatch(actionType: ApiActions, data?: any) {
  //   switch (actionType) {
  //   }
  // }
}

export const api = new ApiPort()
export const apiContext = createContext<ApiPort>(api)
export const useApiContext = () => useContext<ApiPort>(apiContext)

const { Provider } = apiContext

const ApiProvider: React.FC = ({ children }) => {
  return <Provider value={api}>{children}</Provider>
}

export default ApiProvider
