import React, { useState, useEffect } from 'react'
import { Subject, BehaviorSubject, Observable, Subscription } from 'rxjs'

export class RxContext {}

export function useSubject<T>(ob$: Subject<T>, initialValue: T): [T, (value: T) => void] {
  const [value, set] = useState<T>(initialValue)
  useEffect(() => {
    const sub = ob$.subscribe(set)
    return () => sub?.unsubscribe()
  })
  return [value, ob$.next.bind(ob$)]
}

export function useObservable<T>(ob$: Observable<T>, initialValue: T): [T] {
  const [value, set] = useState<T>(initialValue)
  useEffect(() => {
    const sub = ob$.subscribe(set)
    return () => sub?.unsubscribe()
  })
  return [value]
}
