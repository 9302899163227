export const baseFontSize = 18
export const baseLineHeight = 1.4
export const scaleRatio = 2.5
export const headerFontFamily = ['Jost', 'Helvetica Neue', 'Roboto', 'Arial', 'sans-serif']
export const bodyFontFamily = ['Literata', 'Noto Serif', 'Georgia', 'serif']
export const headerWeight = 200
export const bodyWeight = 400
export const boldWeight = 600

export const googleFontsConfig = {
  fonts: [
    {
      font: 'Literata',
      weights: [400, '400i', 600, '600i']
    },
    {
      font: 'Jost',
      weights: [200, 400, 500]
    }
  ],
  subsets: ['cyrillic'],
  display: 'swap'
}

export default {
  baseFontSize,
  baseLineHeight,
  scaleRatio,
  headerFontFamily,
  bodyFontFamily,
  headerWeight,
  bodyWeight,
  boldWeight,
  googleFontsConfig
}
