interface IFont {
  font: string
  weights: Array<any>
}

export const createGoogleFontLink = (fonts: IFont[], subsets: string[], display: string) => {
  const families = fonts
    .reduce((acc, font) => {
      const family = font.font.replace(/ +/g, '+')
      const weights = (font.weights || []).join(',')

      return [...acc, family + (weights && `:${weights}`)]
    }, [])
    .join('|')

  let link = `https://fonts.googleapis.com/css?family=${families}`

  if (subsets && Array.isArray(subsets) && subsets.length > 0) {
    link += `&subset=${subsets.join(',')}`
  }

  if (display) {
    link += `&display=${display}`
  }

  return link
}

export default createGoogleFontLink
