import { useEffect, useLayoutEffect, useRef } from 'react'
export const noop = () => {}
export const isBrowser = () => typeof window !== 'undefined'
export const useIsomorphicLayoutEffect = isBrowser() ? useLayoutEffect : useEffect
export const usePrevious = <T>(value:T) => {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  })
  return ref
}